.card {
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .p-datatable-gridlines .p-datatable-header {
    background-color: #007bff;
    color: white;
  }
  
  .p-datatable-gridlines .p-datatable-header th {
    border-bottom: 2px solid #0056b3;
  }
  
  .p-datatable-gridlines .p-datatable-row:hover {
    background-color: #f1f1f1;
  }
  
  .p-datatable-gridlines .p-datatable-cell {
    padding: 12px;
  }
  
  .p-datatable-gridlines .p-datatable-footer {
    font-weight: bold;
    background-color: #f8f9fa;
  }
  


