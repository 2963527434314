.article-card {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.book-image {
  width: 95%;
  height: auto;
}

.article-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.meta-info {
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 10px;
}

.author, .date {
  display: flex;
  align-items: center;
}

.icon-users::before, .icon-calendar::before {
  font-family: "Dashicons";
  margin-right: 5px;
}

.description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.cta-container {
  display: flex;
  justify-content: center;
}

.cta-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45a049;
}
