.appointments-container {
    padding: 1rem;
    max-width: 100%; /* Limiter la largeur maximale */
    margin: auto; /* Centrer le conteneur */
}

.appointments-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    justify-content: center;
    padding: 0 6rem; /* Ajout de 6rem de padding à gauche et à droite */
}
.appointment-card {
    flex: 1 1 100%; /* Sur mobile, chaque carte prendra 100% de la largeur */
    max-width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Ajout d'une légère ombre */
    margin-bottom: 1.5rem; /* Ajouter un espace entre les cartes */
    border-radius: 8px; /* Rendre les coins des cartes arrondis */
    background-color: white; /* Couleur de fond pour la carte */
}

.status {
    font-weight: bold;
    color: #ff0000; /* Couleur pour le statut "En attente" */
}

@media (min-width: 768px) {
    .appointment-card {
        flex: 1 1 calc(50% - 2rem); /* 2 cartes par ligne sur tablettes */
        max-width: calc(60% - 7rem);
    }
}

@media (min-width: 1024px) {
    .appointment-card {
        flex: 1 1 calc(33.33% - 2rem); /* 3 cartes par ligne sur desktop */
        max-width: calc(33.33% - 2rem);
    }
}


/* Réduire le padding sur les écrans plus petits */
@media (max-width: 1024px) {
    .appointments-grid {
        padding: 0 3rem; /* Réduction à 3rem sur les tablettes */
    }
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 1rem; /* Ajuster le padding pour le rendre plus compact sur mobile */
    width: 100%;
}

.avatar {
    margin-bottom: 1rem;
    width: 50px; /* Ajuster la taille de l'avatar sur mobile */
    height: 50px;
}

.appointment-header {
    display: flex;
    flex-direction: column; /* Sur mobile, afficher les éléments en colonne */
    align-items: flex-start;
    width: 100%;
    margin: 0.5rem 0;
}

@media (min-width: 768px) {
    .appointment-header {
        flex-direction: row; /* Afficher en ligne sur les écrans plus grands */
        justify-content: space-between;
    }
}

.card-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem; /* Ajouter de l'espace au-dessus des boutons */
    width: 100%;
}

/* Description limit to 2 lines */
.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Afficher seulement 2 lignes */
    -webkit-box-orient: vertical;
}

.description.expanded {
    -webkit-line-clamp: unset; /* Afficher tout le texte quand élargi */
}

.toggle-button {
    align-self: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

@media (min-width: 768px) {
    .toggle-button {
        width: auto;
    }
}

/* Styles pour le paginator */
.custom-paginator {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}

/* Styles pour les boutons dans les dialogs */
.p-dialog .p-button {
    width: 100%;
    margin-top: 1rem;
}

@media (min-width: 768px) {
    .p-dialog .p-button {
        width: auto;
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .appointments-grid {
        padding: 0 1rem; /* Réduction à 1rem sur les mobiles */
    }
    .appointment-card {
        margin-top: '5rem'
    }
}