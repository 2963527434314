.sidebar-wrapper {
    background-color: #ECECEC; /* Ancienne couleur du texte */
    color: #2C2C2C; /* Ancienne couleur du fond */
    height: 100vh; /* Assure que la sidebar prend toute la hauteur */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pour que le bouton de déconnexion aille en bas */
  }
  
  .sidebar-wrapper .p-panelmenu .p-menuitem-link {
    background-color: #ECECEC;
    color: #2C2C2C;
  }
  
  .sidebar-wrapper .p-panelmenu .p-menuitem-link:hover {
    background-color: #2C2C2C;
    color: #ECECEC;
  }
  
  .sidebar-wrapper .brand-link {
    background-color: #2C2C2C;
    color: #ECECEC;
  }
  
  .sidebar-wrapper .p-menuitem-link .pi {
    color: #2C2C2C; /* Couleur des icônes à l'intérieur des items du menu */
  }
  
  .sidebar-wrapper .p-menuitem-link:hover .pi {
    color: #ECECEC; /* Couleur des icônes au hover */
  }
  