.grid-item {
  max-width: 350px;  /* Decrease max-width */
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;  /* Decrease border radius */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);  /* Decrease shadow size */
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.grid-inner {
  padding: 8px;  /* Decrease padding */
}

.post-img {
  width: 100%;
}

.post-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;  /* Decrease border radius */
}

.post-header {
  text-align: center;
  margin-top: 10px;  /* Decrease margin */
}

.entry-title a {
  font-size: 1rem;  /* Decrease font size */
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.entry-title a:hover {
  color: #007BFF;
}

.list-author {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;  /* Decrease margin */
  font-size: 0.8rem;  /* Decrease font size */
  color: #555;
}

.list-author img {
  border-radius: 50%;
  margin-right: 5px;  /* Decrease margin */
}

.by {
  margin-right: 3px;  /* Decrease margin */
  font-style: italic;
}

.atc {
  font-weight: bold;
}
