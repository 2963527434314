/* Styles généraux pour les autres images */
.carousel-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Plein écran */
  overflow: hidden;
  display: flex;
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
}



.image-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image couvre tout l'espace */
}

.carousel-text {
  position: absolute;
  top: 50%; /* Centré verticalement */
  left: 50%; /* Centré horizontalement */
  transform: translate(-50%, -50%); /* Ajustement du centrage */
  text-align: center;
  background-color: rgba(129, 127, 127, 0.7); /* Arrière-plan semi-transparent */
  padding: 1rem 2rem;
  border-radius: 1rem;
  color: white;
  z-index: 1; /* Pour s'assurer que le texte soit au-dessus des images */
  font-size: 3.5rem; /* Style de texte initial pour les grands écrans */
  font-family: 'cursive';
  font-weight: bold;
  animation: slideDown 1s ease-in-out forwards;
}

/* Animation pour le texte */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-20px) scale(0.8); /* Départ légèrement en haut et petite taille */
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); /* Taille normale */
  }
}

/* Media Queries pour rendre le texte et l'affichage réactifs */
@media (max-width: 1024px) {
  .carousel-text {
    font-size: 3rem; /* Taille réduite sur les tablettes */
  }
  .carousel-container {
    margin-top: -16rem;
    margin-bottom: -17rem;
  }
}

@media (max-width: 768px) {
  .carousel-text {
    font-size: 2rem; /* Taille plus petite sur les mobiles */
  }
  .carousel-container {
    margin-top: -11.5rem;
    margin-bottom: -9rem;
  }
}

@media (max-width: 480px) {
  .carousel-text {
    font-size: 0.8rem; /* Encore plus petite taille pour les petits écrans */
  }
  .carousel-container {
    margin-top: -14rem;
    margin-bottom: -17rem;
  }
}
