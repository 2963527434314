.notification-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.notification-item:hover {
    background-color: #f9f9f9;
}

.notification-item .pi {
    font-size: 1.5em;
    color: #999;
}

.notification-item .p-text-bold {
    font-weight: bold;
}

.notification-item a {
    text-decoration: none;
    color: #007ad9;
    font-size: 0.85rem;
}

.notification-item a:hover {
    text-decoration: underline;
}
