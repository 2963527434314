/* Container for the quiz list */
.quiz-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Title of the quiz list */
  .quiz-list-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Container for each button */
  .quiz-button-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  /* Quiz button styling */
  .quiz-button {
    width: 100%;
    max-width: 100%;
    font-size: 1.2em;
    padding: 10px 20px;
  }
  
  /* Container for confirmation message and buttons */
  .confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
  }
  
  /* Styling for confirmation message */
  .confirmation-message {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Button styling for starting quiz */
  .start-quiz-button {
    width: 200px;
    margin-bottom: 10px;
  }
  
  /* Button styling for cancel action */
  .cancel-button {
    width: 200px;
  }
  
  /* Spinner container (used when loading data) */
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Media Queries for responsive design */
  
  /* For smaller screens like tablets and mobile devices */
  @media (max-width: 768px) {
    .quiz-list-container {
      padding: 10px;
      margin-top: 4rem;
    }
  
    .quiz-button-container {
      width: 100%;
    }
  
    .quiz-button {
      font-size: 1em;
      padding: 8px 15px;
    }
  
    .confirmation-message {
      font-size: 16px;
    }
  
    .start-quiz-button,
    .cancel-button {
      width: 100%;
    }
  }
  
  /* For very small screens like phones in portrait mode */
  @media (max-width: 480px) {
    .quiz-button {
      font-size: 0.9em;
      padding: 6px 10px;
    }
  
    .confirmation-message {
      font-size: 14px;
    }
  }
  