.accepted-appointments-container {
    padding: 1rem;
    max-width: 91%;
    margin: 0 auto; /* Centrer le contenu */
}

.accepted-appointments-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center; /* Centrer les éléments pour un affichage mobile plus agréable */
}

.accepted-appointment-card {
    flex: 1 1 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border: 1px solid #e0e0e0; /* Bordure légère */
    border-radius: 8px; /* Coins arrondis */
}

@media (min-width: 768px) {
    .accepted-appointment-card {
        flex: 1 1 calc(50% - 2rem); /* 2 cartes par ligne sur tablettes */
        max-width: calc(50% - 2rem);
    }
}

@media (min-width: 1024px) {
    .accepted-appointment-card {
        flex: 1 1 calc(33.33% - 2rem); /* 3 cartes par ligne sur desktop */
        max-width: calc(33.33% - 2rem);
    }
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    margin-top: 5rem;
}

.avatar {
    margin-right: 1rem;
}

.appointment-info {
    flex-grow: 1;
}

.patient-name {
    font-weight: bold;
    margin: 0;
}

.ailment {
    margin: 0;
}

.date-time {
    margin: 0;
    color: #757575; /* Couleur de texte adoucie */
}

/* Description limit to 2 lines */
.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limite à 2 lignes */
    -webkit-box-orient: vertical;
    margin-top: 1rem;
    width: 100%;
    color: #333;
    transition: max-height 0.3s ease;
    max-height: 3rem; /* Ajustez selon la hauteur de ligne */
}

.description.expanded {
    -webkit-line-clamp: unset; /* Supprime la limitation de ligne */
    max-height: none; /* Retirer la limite de hauteur */
}

.toggle-button {
    margin-top: 0.5rem;
    align-self: flex-end;
}
