/* Chat Box */
.chat-box {
  max-height: 500px;
  overflow-y: auto;
  padding: 15px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.bot-message {
  align-self: flex-start;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  line-height: 1.5;
}

.welcome-message, .end-message {
  font-weight: bold;
  color: #333;
  padding: 15px;
  background-color: #e7f3fe;
  border: 1px solid #b3d8fd;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.6;
}


.whatsapp-style {
  background-color: #e5ddd5;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin-bottom: 10px;
}

.user-message {
  display: flex;
  justify-content: end;
  margin-left: 50%;
  background-color: #dcf8c6;
  padding: 8px;
  border-radius: 8px;
  align-items: end;
}

.bot-message {
  align-self: flex-start;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.timestamp {
  font-size: 0.8em;
  color: #999;
  text-align: right;
  margin-top: 5px;
}

.date-header {
  text-align: center;
  font-size: 0.9em;
  color: #555;
  margin: 15px 0;
}

/* Input container */
.whatsapp-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
}

.p-inputtext-lg {
  flex-grow: 1;
  margin-right: 10px;
}

/* Send button */
.send-button {
  background-color: #00bfa5;
  border-radius: 50%;
  padding: 10px;
  width: 45px;
  height: 45px;
}

.whatsapp-send-button {
  background-color: #25d366;
  color: white;
}

/* Options container */
.options-container {
  margin-top: 20px;
  padding: 10px;
}

.option-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.option-item label {
  font-size: 1rem;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

/* Submit button */
.submit-button {
  margin-top: 20px;
  border: none;
}

/* Bot and User message styles */
.bot-message, .user-message {
  margin-bottom: 10px;
}

/* Quiz container */
.quiz-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.chat-card {
  position: relative;
  max-width: 65rem;
  width: 100%;
}

.return-button {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  margin-bottom: 5rem;
  z-index: 10;
  background-color: #2c6b13;
  color: white;
  border-radius: 3rem;
}

.card-header-style {
  font-weight: bold;
  font-size: 1.5rem; /* Adjust the font size as needed */
  color: #333;
  text-align: center;
}

.input-container {
  display: flex;
  justify-content: center;
}

/* Layout for quiz cards and back button */
.quiz-container {
  position: relative;
}

.chat-card {
  position: relative;
}

.return-button {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  margin-bottom: 5rem;
  z-index: 10;
  background-color: #2c6b13;
  color: white;
  border-radius: 3rem;
}

/* Completion message */
.completion-message {
  margin-top: 1rem;
}

/* For the back and next button */
.next-button {
  margin-top: 1rem;
  background-color: #4CAF50;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
}

.next-button:hover {
  background-color: #45a049;
}
.welcome-message,
.end-message {
  padding: 20px;
  text-align: left;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  
  width: 75%;
}

.welcome-message h2,
.end-message h2 {
  color: #ffa500;
  font-weight: bold;
}

.welcome-message p,
.end-message p {
  font-size: 1rem;
  line-height: 1.5;
}

.end-message ul {
  list-style: disc;
  margin-left: 20px;
}




