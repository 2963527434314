.rdv-container {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f9f9f9;
    position: relative;
}

.rdv-content {
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: filter 0.3s ease;
    z-index: 1;
}

.blurred {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
}

.card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 20px 0;
}

.selected {
    border: 2px solid #4caf50; /* Exemple: une bordure verte pour la sélection */
    background-color: #e8f5e9; /* Exemple: un fond vert clair */
}


.service-card,
.doctor-card {
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    padding: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 6px;
}

.service-card:hover,
.doctor-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.selected {
    border: 2px solid #4caf50; /* Exemple: une bordure verte pour la sélection */
    background-color: #e8f5e9; /* Exemple: un fond vert clair */
}

.service-card.selected,
.doctor-card.selected {
    background-color: #95c73a;
    color: #fff;
    border-color: #7fb32b;
}



.time-button.selected {
    background-color: #95c73a;
    color: #fff;
    border-color: #7fb32b;/* Vous pouvez également ajouter un changement de couleur de texte si nécessaire */
}



.doctor-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 2px solid #95c73a;
}

.doctor-info h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
}

.next-button {
    background-color: #95c73a;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
}

.next-button:disabled {
    background-color: #e2d307;
    cursor: not-allowed;
}

.time-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
}

.time-button {
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    padding: 10px 20px;
}

.time-button.selected {
    background-color: #95c73a;
    color: #fff;
    border-color: #7fb32b;
}

.loader-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: transparent;
    overflow: hidden;
    z-index: 2;
}

.progress-line {
    width: 100%;
    height: 100%;
    background-color: #95c73a;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

.confirmation {
    text-align: center;
    padding: 40px 20px;
}

.confirmation p {
    font-size: 1.1em;
    margin: 20px 0;
    line-height: 1.5;
}


@media (max-width: 768px) {
    .container {
        max-width: 100%;
    }

    .card-grid{
        display: flex;
        flex-direction: column;
    }
}